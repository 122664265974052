<template>
  <div>
    <div class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <h1 class="title is-size-1">{{ title }}</h1>
        </div>
      </div>
    </div>
    <div class="container">
      <v-card :loading="loading" class="mb-4 pa-6">
        <v-row>
          <v-col cols="11"> Upload Carrier Billing Rates </v-col>
          <v-col cols="1" align="right">
            <v-tooltip left color="black">
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="primary" dark v-bind="attrs" v-on="on">
                  mdi-information-outline
                </v-icon>
              </template>
              <p><b>Setup rates for a specific carrier service</b></p>
              <li>
                The current rates should appear when the carrier and service is
                selected. If none show up, it means this has not been setup yet.
              </li>
              <li>
                Rates can be set globally, or company specific. To setup company
                specific rates, tick the "Set company specific rates" checkbox.
                If left unticked, it assumes you wish to see/update the global
                rates.
              </li>
              <li>
                To upload new rates either download the rates file from sorted,
                or through the template available on this page.
              </li>
              <li>
                Use the shipping term dropdown before uploading rates if you
                wish to limit rates to a specific shipping term
              </li>
              <li>
                If you wish to update rates for a specific service but they
                already exist, you will need to overwrite them by tickign the
                checkbox.
              </li>
              <li>
                Rates template file help: 'From zone' should always be GB, 'To
                zone' should be a valid zone reference. These can be
                found/created at the page 'Carrier Zones'
              </li>
            </v-tooltip>
          </v-col>
          <v-col cols="6">
            <v-select
              menu-props="auto"
              v-model="selectedCarrier"
              :items="carriersWithServices"
              item-text="name"
              return-object
              label="Select Carrier"
              outlined
              dense
              @change="setCarrierServices"
            ></v-select>
          </v-col>
          <v-col cols="6">
            <v-select
              v-if="selectedCarrier && selectedCarrier.id && carrierServices"
              menu-props="auto"
              v-model="selectedCarrierService"
              :items="carrierServices"
              item-text="service"
              return-object
              label="Select Carrier Service"
              outlined
              dense
              @change="getCarrierBillingRates"
            ></v-select>
          </v-col>
        </v-row>
        <v-row
          v-if="
            selectedCarrier &&
            selectedCarrier.id &&
            selectedCarrierService &&
            selectedCarrierService.id
          "
        >
          <v-col cols="6">
            <v-checkbox
              v-model="companySpecificRates"
              label="Set Company Specific Rates"
              @change="globalRatesToggled"
            ></v-checkbox>
          </v-col>
          <company-select
            v-show="companySpecificRates"
            @chosen="setCompany"
            v-model="companySelected"
          ></company-select>
        </v-row>
        <v-row
          v-if="
            selectedCarrier &&
            selectedCarrier.id &&
            selectedCarrierService &&
            selectedCarrierService.id
          "
        >
          <v-col cols="3">
            <v-btn color="green" outlined @click="downloadRatesTemplate"
              >Download Rates Template</v-btn
            >
          </v-col>
          <v-col cols="3">
            <v-file-input
              show-size
              counter
              multiple
              dense
              outlined
              prepend-icon=""
              prepend-inner-icon="attach_file"
              label="File input"
              @change="handleFileUpload"
            ></v-file-input>
          </v-col>
          <v-col cols="3">
            <v-checkbox
              class="mt-0"
              v-model="overwriteRates"
              label="Overwrite rates if they already exist"
            >
            </v-checkbox>
          </v-col>
          <!-- shipping terms -->
          <v-col cols="3">
            <v-select
              menu-props="auto"
              v-model="selectedShippingTerm"
              :items="shippingTerms"
              item-text="description"
              item-value="value"
              label="Select Shipping Term (leave empty to apply rates to any shipping term)"
              outlined
              dense
            ></v-select>
          </v-col>
          <v-col cols="3">
            <v-btn
              color="primary"
              outlined
              :disabled="rates.length < 1"
              @click="insertCarrierBillingRates"
              >Save Billing Rates
            </v-btn>
          </v-col>
          <v-col cols="12" v-if="rates.length">
            <v-card outlined class="pa-2">
              <v-card-actions>
                <div>Preview of uploaded file</div>
                <v-spacer></v-spacer>

                <v-btn icon @click="showPreview = !showPreview">
                  <v-icon>{{
                    showPreview ? "mdi-chevron-up" : "mdi-chevron-down"
                  }}</v-icon>
                </v-btn>
              </v-card-actions>
              <v-expand-transition>
                <div v-show="showPreview">
                  <v-data-table
                    :headers="ratesHeaders"
                    :items="rates"
                    :items-per-page="5"
                  ></v-data-table>
                </div>
              </v-expand-transition>
            </v-card>
          </v-col>
          <v-col cols="12" v-if="currentCarrierBillingRates.length">
            <v-card outlined class="pa-2">
              <v-card-title
                >Current
                {{ companySelected ? companySelected.name : "Global" }} Rates
                for {{ selectedCarrierService.service }}</v-card-title
              >
              <v-data-table
                :headers="currentCarrierBillingRatesHeaders"
                :items="currentCarrierBillingRates"
                :items-per-page="5"
              >
                <template v-slot:header.weight_from="{ header }">
                  <div :class="updating ? 'updating-form' : ''">
                    {{ header.text }}
                  </div>
                </template>
                <template v-slot:header.weight_to="{ header }">
                  <div :class="updating ? 'updating-form' : ''">
                    {{ header.text }}
                  </div>
                </template>
                <template v-slot:header.cost="{ header }">
                  <div :class="updating ? 'updating-form' : ''">
                    {{ header.text }}
                  </div>
                </template>
                <template v-slot:item.toZone.reference="{ item }">
                  <div v-if="updating" style="max-width: 130px">
                    {{ item.toZone.reference }}
                  </div>
                  <div v-else>
                    {{ item.toZone.reference }}
                  </div>
                </template>
                <template v-slot:item.weight_from="{ item }">
                  <v-text-field
                    class="no-padding"
                    v-if="updating && updateForm.id === item.id"
                    type="number"
                    v-model="updateForm.weight_from"
                    label="weight from"
                    dense
                    outlined
                    step="0.01"
                  ></v-text-field>
                  <div v-else>
                    {{ item.weight_from }}
                  </div>
                </template>
                <template v-slot:item.weight_to="{ item }">
                  <v-text-field
                    v-if="updating && updateForm.id === item.id"
                    type="number"
                    v-model="updateForm.weight_to"
                    label="weight to"
                    dense
                    outlined
                    step="0.01"
                  ></v-text-field>
                  <div v-else>
                    {{ item.weight_to }}
                  </div>
                </template>

                <template v-slot:item.cost="{ item }">
                  <v-text-field
                    v-if="updating && updateForm.id === item.id"
                    type="number"
                    v-model="updateForm.cost"
                    label="cost"
                    dense
                    outlined
                    step="0.01"
                  ></v-text-field>
                  <div v-else>
                    {{ item.cost }}
                  </div>
                </template>

                <template v-slot:item.action="{ item }">
                  <v-btn
                    v-if="updating && updateForm.id === item.id"
                    small
                    @click="resetUpdatingRate()"
                    color="red"
                    class="mb-1"
                    outlined
                  >
                    <b>X</b>
                  </v-btn>
                  <v-btn
                    v-if="updating && updateForm.id === item.id"
                    small
                    @click="updateRate(item)"
                    color="primary"
                    outlined
                  >
                    <v-icon> check </v-icon>
                  </v-btn>
                  <v-btn
                    v-if="!updating"
                    small
                    @click="toggleUpdateRate(item)"
                    color="primary"
                    class="mb-1"
                    outlined
                  >
                    <v-icon> edit </v-icon>
                  </v-btn>
                  <v-btn
                    v-if="!updating"
                    small
                    @click="deleteRate(item)"
                    color="red"
                    outlined
                  >
                    <v-icon> delete </v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </div>
    <v-snackbar v-model="snackbar" :timeout="3000">
      {{ text }}
      <v-btn color="blue" text @click="snackbar = false"></v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import Carriers from "@/services/Carriers.js";
import ExcelMixin from "@/mixins/Excel";
import CompanySelect from "@/components/forms/CompanySelect";
export default {
  mixins: [ExcelMixin],
  components: { CompanySelect },
  data() {
    return {
      validForm: true,
      // Title
      title: "Carrier Billing Rates",
      // Snackbar
      snackbar: false,
      text: "",
      loading: false,

      carriersWithServices: [],
      selectedCarrier: null,
      carrierServices: null,
      selectedCarrierService: null,
      overwriteRates: false,

      currentCarrierBillingRates: [],
      currentCarrierBillingRatesHeaders: [
        { text: "Weight From", value: "weight_from" },
        { text: "Weight To", value: "weight_to" },
        { text: "Description", value: "description" },
        { text: "From Zone", value: "fromZone.reference" },
        { text: "To Zone", value: "toZone.reference" },
        { text: "Cost", value: "cost" },
        { text: "Currency", value: "currency" },
        { text: "Valid From", value: "valid_from" },
        { text: "Valid To", value: "valid_to" },
        { text: "Prioritise", value: "prioritise" },
        { text: "Shipping Term", value: "shipping_term" },
        { text: "", value: "action" },
      ],

      file: null,
      showPreview: false,
      rates: [],
      ratesHeaders: [
        { text: "Weight From", value: "weight_from" },
        { text: "Weight To", value: "weight_to" },
        { text: "Description", value: "description" },
        { text: "From Zone", value: "from_zone" },
        { text: "To Zone", value: "to_zone" },
        { text: "Cost", value: "cost" },
        { text: "Currency", value: "currency" },
        { text: "Valid From", value: "valid_from" },
        { text: "Valid To", value: "valid_to" },
        { text: "Prioritise", value: "prioritise" },
      ],
      companySelected: null,
      companySpecificRates: false,
      shippingTerms: [
        { description: "apply to all", value: "" },
        { description: "DDU", value: "DDU" },
        { description: "DDP", value: "DDP" },
        { description: "DDP - IOSS" , value: "DDP - IOSS"}
      ],
      selectedShippingTerm: null,

      updateForm: {},
      updating: false,
    };
  },

  created() {
    this.getCarriersWithServices();
  },
  methods: {
    resetUpdatingRate() {
      this.updating = false;
      this.updateForm = {};
    },
    updateRate(item) {
      const updateForm = this.updateForm;
      const reqBody = {
        id: item.id,
        weight_from: updateForm.weight_from,
        weight_to: updateForm.weight_to,
        cost: updateForm.cost,
      };
      Carriers.updateCarrierBillingRate(reqBody)
        .then((response) => {
          this.loading = false;
          this.snackbar = true;
          this.text = `${response.message}`;
          this.resetUpdatingRate();
          this.getCarrierBillingRates();
        })
        .catch((error) => {
          this.snackbar = true;
          this.text = error.response
            ? error.response.data.message
            : `${error.message}`;
          this.loading = false;
        });
    },
    toggleUpdateRate(item) {
      this.updating = true;
      this.updateForm = { ...item };
    },
    deleteRate(item) {
      let confirmMsg = `Are you sure you want to delete rate for ${item.toZone.description}`;
      if (confirm(confirmMsg)) {
        this.loading = true;
        let reqBody = {
          id: item.id,
        };
        Carriers.deleteCarrierBillingRate(reqBody)
          .then((response) => {
            this.loading = false;
            this.snackbar = true;
            this.text = `${response.message}`;

            this.getCarrierBillingRates();
          })
          .catch((error) => {
            this.snackbar = true;
            this.text = error.response
              ? error.response.data.message
              : `${error.message}`;
            this.loading = false;
          });
      }
    },
    globalRatesToggled() {
      this.currentCarrierBillingRates = [];
    },
    setCompany(company) {
      this.companySelected = company;
      this.getCarrierBillingRates(company.id);
    },
    setCarrierServices(carrier) {
      this.carrierServices = carrier.carrierServices;
    },
    async handleFileUpload(ev) {
      const file = ev[0];
      this.file = file;

      if (file) {
        this.extractJson(file)
          .then((jsonSpreadsheet) => {
            this.rates = this.extractRatesFromSpreadsheet(jsonSpreadsheet);
          })
          .catch((err) => {
            this.snackbar = true;
            this.text = err;
          });
      }
    },
    extractRatesFromSpreadsheet(spreadsheet) {
      let tempArray = [];
      spreadsheet.forEach((row) => {
        let cost = row["Cost"] ? row["Cost"] : row[" Cost "];
        let tempObj = {
          from_zone: row["From Zone"],
          to_zone: row["To Zone"],
          description: row.Description,
          weight_from: row["Weight from"],
          weight_to: row["Weight to"],
          cost: cost,
          currency: row["Currency"],
          valid_from: row["Valid From"],
          valid_to: row["Valid To"],
          prioritise: row["Prioritise"] ?? 0,
        };
        tempArray.push(tempObj);
      });
      return tempArray;
    },
    getCarriersWithServices() {
      this.loading = true;
      Carriers.getCarriersWithServices()
        .then((response) => {
          this.loading = false;
          this.snackbar = true;
          this.text = `${response.message}`;
          this.carriersWithServices = response.data;
        })
        .catch((error) => {
          this.snackbar = true;
          this.text = error.response
            ? error.response.data.message
            : `${error.message}`;
          this.loading = false;
        });
    },
    validateCompanySelected() {
      if (this.companySpecificRates && !this.companySelected) {
        this.snackbar = true;
        this.text = `Company specific rates is checked, but no company is selected`;
        return false;
      } else {
        return true;
      }
    },
    getCarrierBillingRates() {
      let valid = this.validateCompanySelected();
      if (!valid) return;
      this.loading = true;
      let companyId =
        this.companySpecificRates && this.companySelected
          ? this.companySelected.id
          : null;
      this.currentCarrierBillingRates = [];
      Carriers.getCarrierBillingRates(
        this.selectedCarrier.id,
        this.selectedCarrierService.id,
        companyId
      )
        .then((response) => {
          this.loading = false;
          this.snackbar = true;
          this.text = `${response.message}`;
          this.currentCarrierBillingRates = response.data;
        })
        .catch((error) => {
          this.snackbar = true;
          this.text = error.response
            ? error.response.data.message
            : `${error.message}`;
          this.loading = false;
        });
    },
    downloadRatesTemplate() {
      let template = {
        "Weight from": 0,
        "Weight to": 30,
        Description: "UK to UK",
        "From Zone": "GB",
        "To Zone": "DPD_UK_EXCLUDING_NI_AND_SCOTTISH_H_AND_I",
        " Cost ": 3.75,
        Currency: "GBP",
        "Valid From": "28/02/2018",
        "Valid To": "",
        Prioritise:
          "integer between 0 and 3, higher values will be prioritised in case multiple services are in the same service group",
      };
      this.generateTemplate(template, "rates_template");
    },

    insertCarrierBillingRates() {
      if (!this.selectedCarrierService.id || this.rates.length < 1)
        return false;
      let valid = this.validateCompanySelected();
      if (!valid) return false;
      let numRows = this.rates.length;
      let confirmMsg = `Are you sure you want to add ${numRows} ${
        numRows === 1 ? "rate" : "rates"
      }  to service  ${this.selectedCarrierService.service}`;
      if (confirm(confirmMsg)) {
        this.loading = true;
        let reqBody = {
          carrier_id: this.selectedCarrier.id,
          carrier_service_id: this.selectedCarrierService.id,
          rates: this.rates,
          overwrite: this.overwriteRates,
        };
        if (this.companySpecificRates && this.companySelected) {
          reqBody.company_id = this.companySelected.id;
        }
        if (this.selectedShippingTerm) {
          reqBody.shipping_term = this.selectedShippingTerm;
        }

        Carriers.insertCarrierBillingRates(reqBody)
          .then((response) => {
            this.loading = false;
            this.snackbar = true;
            this.text = `${response.message}`;
            this.rates = [];
          })
          .catch((error) => {
            this.snackbar = true;
            this.text = error.response
              ? error.response.data.message
              : `${error.message}`;
            this.loading = false;
          });
      }
    },
  },
};
</script>

<style scoped>
.updating-form {
  min-width: 85px;
}
</style>
